import React, { useEffect } from 'react';
import { useStore } from '../../hook/useStore';
import SecondHeader from '../../components/SecondHeader';
import ButtonIconMain from '../../components/ButtonIconMain';
import Container from '@material-ui/core/Container';

// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SubjectIcon from '@material-ui/icons/Subject';
import AddLocation from '@material-ui/icons/Place';
import Message from '@material-ui/icons/Message';
import Outline from '@material-ui/icons/ErrorOutline';
import PersonAdd from '@material-ui/icons/PersonAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import InstallAndroid from '../..//components/InstallAndroid';

import { useAuth } from '../../hook/useAuth';

export default function Home(showAndroidInstall) {
  const { getUser } = useAuth();

  const { setTitle, setTopShadow } = useStore();
  useEffect(async () => {
    setTitle('Home');
    setTopShadow(false);
  }, []);



  console.log('showAndroidInstall', showAndroidInstall);

  return (
    <>
      <SecondHeader></SecondHeader>

      <br></br>

      <Container>

      {showAndroidInstall && <InstallAndroid />}

        {!getUser() &&
          <ButtonIconMain text="Cadastrar" Icon={PersonAdd} link="/registrar" reverse={true} />
        }

        <ButtonIconMain

          text="Dicas de Separação"
          Icon={DeleteSweepIcon}
          link="/dicas"
        />
        {
          <ButtonIconMain
            text="Onde está o Caminhão"
            Icon={LocalShippingIcon}
            link="/caminhoes"
          />
        }
        {
          <ButtonIconMain
            text="Grade de Coletas/Bairros"
            Icon={LibraryBooksIcon}
            link="/coleta"
          />
        }
        <ButtonIconMain text="Rotas" Icon={AddLocation} link="/rotas" />
        {/* {getUser() && <ButtonIconMain text="Tempo aproximado" Icon={SubjectIcon} link="/" /> } */}
        {getUser() && (
          <ButtonIconMain text="Alerte-me" Icon={Outline} link="/alerta" />
        )}
        {getUser() && (
          <ButtonIconMain text="Contato" Icon={Message} link="/contato" />
        )}
      </Container>
    </>
  );
}

