import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TextFieldAsagee from '../../components/TextFieldAsagee';
import ButtonAsagee from '../../components/ButtonAsagee';
import MuiAlert from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import api from '../../services/api';
import { useStore } from '../../hook/useStore';
import { useAuth } from '../../hook/useAuth';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import ArrowBack from '../../components/ArrowBack';

import validator from 'validator';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      // mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#999',
    fontWeight: 900,
    fontSize: '16px',
    marginBottom: 15,
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    fontSize: '16px',
    marginBottom: 15,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Register() {
  const [term, setTerm] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');

  const [email, setEmail] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneSanitize, setPhoneSanitize] = useState('');

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [message, setMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState('success');

  const classes = useStyles();
  const { storeToken, setUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    setPhoneSanitize(phone.replace(/[^0-9.]/g, ''));
  }, [phone]);

  function handleCheckbox(event) {
    console.log(event.target.checked);

    if (term) {
      setTerm(false);
    } else {
      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setTerm(true);
    setOpen(false);
  };

  const handleSubmitForm = async () => {

    if (!isValidEmail()) {
      toast.error('E-mail invalido');
      return false;
    }

    if (!isValidPassword()) {
      toast.error('Senha invalida');
      return false;
    }

    if (!isValidName()) {
      toast.error('Nome invalido');
      return false;
    }

    if (!term) {
      toast.error('Termo de uso obrigatorio');
      return false;
    }

    setLoading(true);

    try {
      const data = await api.post('register', {
        email,
        name,
        password,
        password_confirmation: password2,
        phone: phoneSanitize,
      });

      if (data.status === 201) {
        if (data.data.data.token) {
          storeToken(data.data.data.token);
          setUser(data.data.data.user);
        }
        history.push('/');
      }
    } catch (error) {
      console.log(error.response);
      let message =
        error &&
          error.response &&
          error.response.data &&
          error.response.data.data
          ? error.response.data.data
          : 'Problema ao cadastrar!';
      setLoading(false);
      setMessageType('error');
      setMessage(message);
      setShowMessage(true);
    }

    setLoading(false);
  };

  const isValidName = () => {
    if (validator.isEmpty(name)) return false;
    if (!validator.isLength(name, { min: 3, max: 60 })) return false;
    return true;
  };

  const isValidEmail = () => {
    if (validator.isEmpty(email)) return false;
    if (!validator.isEmail(email)) return false;

    return true;
  };

  const isValidPassword = () => {
    if (validator.isEmpty(password)) return false;
    if (validator.isEmpty(password2)) return false;

    if (password !== password2) return false;

    if (!validator.isLength(password, { min: 4, max: 20 })) return false;

    return true;
  };

  const isValidPhone = () => {
    if (validator.isEmpty(phoneSanitize)) return false;

    if (!validator.isLength(phoneSanitize, { min: 10, max: 11 })) return false;
    return true;
  };

  function handleClick(...goto) {
    if (goto[0] != null) {
      history.push(goto[0]);
    } else {
      history.goBack();
    }
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        onClose={() => setShowMessage(false)}
      >
        <Alert severity={messageType}>{message}</Alert>
      </Snackbar>

      <Container maxWidth="sm">
        <ArrowBack click={handleClick} />

        <Typography variant="h6" className={classes.title}>
          Confirme seus dados, informe seu e-mail e crie uma senha para seus
          futuros acessos.
        </Typography>

        <Box my={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Dados Pessoais
          </Typography>
          <TextFieldAsagee
            error={!isValidName()}
            required
            value={name}
            onChange={(event) => setName(event.target.value)}
            fullWidth
            id="outlined-basic"
            label="Nome"
            variant="outlined"
          />
          <TextFieldAsagee
            error={!isValidEmail()}
            required
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
          />
          <TextFieldAsagee
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            error={!isValidPhone()}
            required
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
            fullWidth
            id="outlined-basic"
            label="Whatsapp"
            variant="outlined"
          />
        </Box>

        <Box my={2}>
          <Typography variant="h6" className={classes.subTitle}>
            Senha
          </Typography>
          <TextFieldAsagee
            error={!isValidPassword()}
            required
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            type="password"
            id="outlined-basic"
            label="Senha"
            variant="outlined"
          />
          <TextFieldAsagee
            error={!isValidPassword()}
            required
            value={password2}
            onChange={(event) => setPassword2(event.target.value)}
            fullWidth
            type="password"
            id="outlined-basic"
            label="Repita a senha"
            variant="outlined"
          />
        </Box>
        <Box my={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={term}
                onChange={handleCheckbox}
                name="checkedC"
                color="primary"
              />
            }
            label="Declaro que li e concordo com os Termos de Uso desse aplicativo."
          />
        </Box>

        <Box my={2}>
          <ButtonAsagee
            onClick={handleSubmitForm}
            fullWidth
            variant="contained"
            color="primary"
          >
            Cadastrar
          </ButtonAsagee>
        </Box>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Termos de uso</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography>
                O presente aceite e termos de uso aplica-se ao Aplicativo da
                Coleta Seletiva do Município de Xangri-lá – APP COLETA
                SELETIVA, não sendo válido para outros aplicativos ou sites
                disponibilizados pela Prefeitura Municipal de Xangri-lá.
                <br />
                O APP COLETA SELETIVA acessa, coleta, utiliza e disponibiliza
                informações de identificação pessoal, como telefone e endereço,
                número de acesso ao APP, entre outros para a Prefeitura
                Municipal de Xangri-lá e para a Associação de Agentes
                Econômicos e Ecológicos de Xangri-lá – ASAGEE.
                <br />
                O Município de Xangri-lá e a ASAGEE têm o compromisso de
                proteção à privacidade dos dados dos usuários do aplicativo não
                se envolvendo na prática de venda ou intercâmbio de dados
                pessoas com terceiros para fins promocionais.
                <br />
                Além das informações pessoais que você nos fornece (telefone e
                localização), o APP COLETA SELETIVA utiliza tecnologia que
                permite ao Município de Xangri-lá e a ASAGEE coletar
                determinadas informações técnicas. Estas informações técnicas
                incluem seu endereço de protocolo de internet e o sistema
                operacional do seu dispositivo
                <br />
                Ao utilizar o APP COLETA SELETIVA, você reconhece e aceita a
                coleta, utilização e divulgação de informações de identificação
                pessoal para os fins descritos no presente aceite e termos de
                uso.
                <br />
                O APP COLETA SELETIVA também poderá fornecer dados estatísticos
                e compartilhar informações gerais a respeito do funcionamento e
                uso do APP e modelos de tráfego, além de outras informações
                relacionadas ao APP COLETA SELETIVA a terceiros idôneos,
                ressalvando-se informações pessoais dos usuários.
                <br />O APP COLETA SELETIVA não fornecerá informações pessoais a
                terceiros sem sua permissão, exceto quando responder às suas
                solicitações ou consultas, ou se, de boa-fé, o APP COLETA
                SELETIVA acreditar que a divulgação das informações é necessária
                para responder a reclamações de que o conteúdo que você submeteu
                infringe os direitos de terceiros ou é necessário para proteger
                os direitos, propriedade e/ou segurança do Município de Xangri-lá
                e a da ASAGEE, dos usuários do APP e/ou do público em
                geral.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Voltar
            </Button>
            <Button onClick={handleAccept} color="primary">
              Aceitar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
