import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, useJsApiLoader, KmlLayer } from '@react-google-maps/api';
// const ScriptLoaded = require("../../docs/ScriptLoaded").default;  
// import kml from './file.kmz'

export default function Teste() {
  const mapContainerStyle = {
    height: "450px",
    width: "100%"
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBU1piSGbsRXoT98P0dcsBt9dwc0ptPaJE"
  })


  const [lat, setLat] = useState(-29.7588);
  const [long, setLong] = useState(-50.0476);


  const [kmz, setKmz] = useState(-50.0476);

  const center = { lat: lat, lng: long };

  return isLoaded ? (
    <>
      <GoogleMap
        id="kml-layer-example"
        mapContainerStyle={mapContainerStyle}
        zoom={11}
        center={center}
      >
        {/* <KmlLayer url="http://googlemaps.github.io/js-v2-samples/ggeoxml/cta.kml" /> */}
        {/* <KmlLayer url="https://developers.google.com/maps/documentation/javascript/examples/kml/westcampus.kml" /> */}
        {/* <KmlLayer url="https://reciclarcapaodacanoa.com.br/file.kmz" /> */}
        <KmlLayer url={kmz} />
      </GoogleMap>

      <input type="number" onChange={e => setLat(parseFloat(e.target.value))} value={lat} />
      <input onChange={e => setLong(parseFloat(e.target.value))} value={long} />

      <br />
      <br />
      <br />
      <hr />
      <hr />
      <hr />
      <br />
      <br />
      <br />


      {[
        'https://reciclarcapaodacanoa.com.br/file223.kmz',
      ].map(item => (
        <>
          <button onClick={() => setKmz(item)}>
            {item}
          </button>
          <br />
          <br />
        </>
      )
      )}

    </>
  ) : 'api fail';
}