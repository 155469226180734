import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LogoMd from '../assets/images/logo-reciclar-v.svg';
import Logo from '../assets/images/logo-reciclar-h-white.svg';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    shape: {
        position: 'relative',
        backgroundColor: theme.palette.primary.main,
        padding: '30px 0',
        color: "#ffffff"
    },
    shapeSvg: {
        position: 'absolute',
        height: '100px',
        bottom: 0,
        left: 0,
        width: '100%'
    },
    image: {
        display: 'block',
        padding: '0 50px',
        width: 'calc(100% - 100px)',
        maxWidth: '350px',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.up("sm")]: {
            maxWidth: '250px',
        },


    }
}));

function SecondHeader({ }) {

    const theme = useTheme();
    const sm = theme.breakpoints.values.sm;
    const classes = useStyles();

    return (
        <>
            <div className={classes.shape}>
                <svg className={classes.shapeSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path fill="currentColor" d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"></path>
                </svg>
                <picture>
                <source media={`(max-width: ${sm}px)`} srcSet={Logo}/>
                <img className={classes.image} src={LogoMd} alt=""/>
            </picture>
            </div>
        </>
    );
}

export default SecondHeader;