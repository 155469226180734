import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';

export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }) => {

    const [menu, setMenu] = useState(false);
    const [title, setTitle] = useState(null);
    const [topShadow, setTopShadow] = useState(true);
    const [menuActive, setMenuActive] = useState('/');
    const [app, setApp] = useState({});

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => { 
            //console.log(history.goBack());
            console.log('location.pathname', location.pathname);
            setMenuActive(location.pathname); 
        }) 
     },[history])

    return (
        <StoreContext.Provider 
        value={{

            menu,
            setMenu,

            title,
            setTitle,

            topShadow,
            setTopShadow,

            menuActive,
            setMenuActive,

            app,
            setApp
        }} >
            {children}
        </StoreContext.Provider>
    )
}