import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CardActions,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Switch,
    Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 900
    },
    delete: {
        marginLeft: 'auto',
    }
    
}));

export default function Address({ address, handleDeleteAddress, handleChangeAlert }) {

    const [checked, setChecked] = React.useState(address.receive_alert? true : false);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('female');
    const radioGroupRef = React.useRef(null);
    
    useEffect(() => {
        setChecked(address.receive_alert? true : false);
    }, [address]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (newValue) => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    const handleEntering = () => {
        console.log('radioGroupRef', radioGroupRef)
        if (radioGroupRef.current != null) {
            console.log('radioGroupRef.current.focus()', radioGroupRef.current.focus())
            radioGroupRef.current.focus();
        }
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const toggleChecked = (event) => {
        handleChangeAlert(address.id, !checked);
    };

    const timeToText = time => {
        switch (time) {
            case 1:
                return '30 Minutos Antes'
                break;
            case 2:
                return '1 Hora antes'
                break;
            case 3:
                return '2 Horas antes'
                break;

            default:
                break;
        }
    }

    const classes = useStyles();

    return (
        <Box my={3}>

            <Card mt={4} >

                <CardContent>

                    <Typography color="textSecondary" gutterBottom>
                        Endereço
                    </Typography>

                    <Typography variant="h5" component="h2">
                        {address.street}, {address.number}
                    </Typography>

                </CardContent>

                <CardActions disableSpacing>
                    <Typography>
                        Alerta:
                        <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>Desativado</Grid>
                            <Grid item>
                            <FormControlLabel
                            control={<Switch checked={checked} onChange={toggleChecked} color='primary'  />}
                            />
                            </Grid>
                        <Grid item>Ativado</Grid>
                        </Grid>
                    </Typography>
                    <IconButton onClick={() => handleDeleteAddress(address.id)} className={classes.delete}>
                        <DeleteForeverIcon />
                    </IconButton>
                </CardActions>

            </Card>

        </Box>
    )
}
