import { Container, Box, Typography, Link, Button } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SecondHeader from '../../components/SecondHeader'
import React, { useEffect } from 'react';
import { useStore } from '../../hook/useStore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#35e676',
        color: "#fff"
    },
}));

export default function Help() {

    const { setTitle, app } = useStore();

    const configs = app.configs;

    const whatsapp = configs && configs.filter(item=>item.tag === 'whatsapp')[0];
    const contactText = configs && configs.filter(item=>item.tag === 'help_text')[0];
    const whatsappMessage = configs && configs.filter(item=>item.tag === 'whatsapp_message_contact')[0];

    const classes = useStyles();
    
    useEffect(() => {
        setTitle('Ajuda');
    }, []);

    return (
        <>
            <SecondHeader></SecondHeader>
            <Container>
            <Box my={4}>

                <Typography>
                    {contactText && contactText.content && contactText.content}
                </Typography>
                <Box my={4} style={{ textAlign: 'center' }}>
                {whatsapp && whatsapp.content &&
                    <Link underline="none" href={`https://api.whatsapp.com/send?phone=55${whatsapp.content}${whatsappMessage && whatsappMessage.content? '&text='+whatsappMessage.content : ''}`} target="_blank">
                        <Button

                            variant="contained"
                            className={classes.button}
                            startIcon={<WhatsAppIcon />}

                        >
                            Whatsapp
                        </Button>
                    </Link>
                }

                </Box>
            </Box>
            </Container>
        </>
    );
}