import react from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import SelectAsagee from './SelectAsagee';

const useStyles = makeStyles((theme) => ({
    root: {
        [`& .MuiOutlinedInput-root`]: {
            borderRadius: theme.asagee.radius
        },
    },

}));

export default function ({ routes, route, handleChange, className = null }) {

    const classes = useStyles();

    return (
        <FormControl className={[classes.root, className]} variant="outlined" fullWidth>

            <SelectAsagee
                labelId="simple-select-helper-label"
                id="simple-select-helper"
                value={route}
                onChange={handleChange}
            >
                <MenuItem value={0}>
                    <em>Selecione</em>
                </MenuItem>

                {routes.map(item => (
                    <MenuItem value={item}>{item.title}</MenuItem>
                ))}

            </SelectAsagee>

            {/* <FormHelperText>Some important helper text</FormHelperText> */}
        </FormControl>
    );
}
