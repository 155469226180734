import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { useStore } from '../../hook/useStore';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CollectionTable from '../../components/CollectionTable';


const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 900
    }
}));

export default function CollectionGrid() {

    const { setTitle, app } = useStore();
    const classes = useStyles();
    const list = app.roads;
    useEffect(async () => {
        setTitle('Grade Coletas/Bairros');
    }, []);   

    return (
        <Container>

            <Box mt={4}>
                <Typography variant="h6" className={classes.title}>Cronograma da coleta seletiva de Xangri-lá: Manhã/Tarde</Typography>
            </Box>
            <Box my={4}>
                <CollectionTable list={list} />
            </Box>

        </Container>
    );
}