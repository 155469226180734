// A PRINCIPIO ISSO NAO ESTA SENDO USADO

import React from 'react';
import { GoogleMap, LoadScript, useJsApiLoader, KmlLayer } from '@react-google-maps/api';
import TruckIcon from '../assets/images/garbage-truck.svg'

export default function ({trucks, kmz, center, zoom = 11 }) {

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBU1piSGbsRXoT98P0dcsBt9dwc0ptPaJE"
    })

    return isLoaded ? <GoogleMap
        id="kml-layer-example"
        mapContainerStyle={containerStyle}
        options={{ streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
        zoom={11}
        center={center}
    >
        <KmlLayer url={kmz} />
    </GoogleMap>
        : 'Carregando mapa...';
}
