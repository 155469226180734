import { ArrowBack as Arrow } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export default function ArrowBack({ click = null, goto = null, style }) {
  const useStyles = makeStyles((theme) => ({
    Arrow: {
      borderSpacing: 0,
      border: '1px solid #bbb',
      borderRadius: '16.5px',
      cursor: 'pointer',
      color: theme.palette.primary.main,
      marginBottom: '10px',
      marginTop: '10px',
      fontSize: '38px',
    },
  }));

  function handleClick(event) {
    if (click) {
      click(goto);
    }
  }
  const classes = useStyles();
  return <Arrow className={classes.Arrow} onClick={handleClick} />;
}
