
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Map from './Map';
import SelectRoute from './SelectRoute';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  select: {
    position: 'absolute',
    zIndex: 1,
    maxWidth: "500px",
    width: '90%',
    marginTop: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    paddingLeft: 20,
    paddingRight: 20,
  },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogRoute({ routes, route, setRoute }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    console.log('event', event);
    setRoute(event.target.value);
    setOpen(false);
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Button variant="outlined" size="small" color="primary" onClick={handleClickOpen}>
            {!route ? 'Escolher Rota' : 'Alterar Rota'}
          </Button>
        </Grid>
        <Grid item xs={5}>
          {route && route != 0 ? (<Typography variant="h6" style={{fontSize: '14px'}} my={3} > {route.title} </Typography>) : null}
        </Grid>
      </Grid>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Selecione sua Rota
            </Typography>
          </Toolbar>
        </AppBar>
        <Box style={{ height: '100%' }}>
          <SelectRoute route={route} routes={routes} className={classes.select} handleChange={handleChange}></SelectRoute>
          <Map kmz="https://reciclarxangrila.com.br/file.kmz" />
        </Box>
      </Dialog>
    </div>
  );
}