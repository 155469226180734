import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import AppBar from '@material-ui/core/AppBar';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import SubjectIcon from '@material-ui/icons/Subject';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import BackSvg from '../assets/images/back.svg';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useStore } from '../hook/useStore';
import { useHistory } from "react-router-dom";

const Icon = props => {
    return (
        <svg baseProfile="tiny" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
            <path d="M12,9.059V6.5c0-0.256-0.098-0.512-0.293-0.708C11.512,5.597,11.256,5.5,11,5.5s-0.512,0.097-0.707,0.292L4,12l6.293,6.207  C10.488,18.402,10.744,18.5,11,18.5s0.512-0.098,0.707-0.293S12,17.755,12,17.5v-2.489c2.75,0.068,5.755,0.566,8,3.989v-1  C20,13.367,16.5,9.557,12,9.059z" />
        </svg>
    );
}



const useStyles = makeStyles({
    // regra de estilo
    foo: props => ({
        backgroundColor: props.backgroundColor,
    }),
    bar: {
        // propriedade CSS
        color: props => props.color,
    },
});

function BottomNav() {
    const history = useHistory();
    const classes = useStyles();

    let { setMenu, menuActive } = useStore();


    function handleChangeMenu(event, newValue) {
        if (newValue === 'toggleMenu') {
            setMenu(true);
            return;
        }

        if(newValue === "handleBack"){
            history.push('/');
            return;
        }

        history.push(newValue);
    }

    return (
        <AppBar position="static" color="primary" >
            <BottomNavigation
                value={menuActive}
                onChange={handleChangeMenu}

                showLabels
                className={classes.root}
            >
                <BottomNavigationAction value="handleBack" icon={<SvgIcon>
                    <Icon></Icon>
                </SvgIcon>} />
                {/* <BottomNavigationAction value="toggleMenu" icon={<SubjectIcon />} /> */}
                <BottomNavigationAction value="/" icon={<HomeIcon />} />
                <BottomNavigationAction value="/caminhoes" icon={<LocalShippingIcon />} />

            </BottomNavigation>
        </AppBar>
    );
}

export default BottomNav;