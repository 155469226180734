import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  KmlLayer,
  MarkerProps
} from '@react-google-maps/api';
// import * as trucksData from '../data/trucks.json';
import TruckIcon from '../assets/images/garbage-truck.svg';
import PlateIcon from '../assets/images/plate.svg';

const containerStyle = {
  width: '100%',
  height: '100%',
};
const zindex = {
  zIndex: 12,
};
const center = { lat: -29.813754011394415, lng: -50.04702368848695 };
const zoom = 13;

function Map({ trucks, kmz = null}) {
  const [selectedTruck, setSelectedTruck] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBU1piSGbsRXoT98P0dcsBt9dwc0ptPaJE',
  });

  const [map, setMap] = useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      options={{ streetViewControl: false, mapTypeControl: false }}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      {kmz && <KmlLayer url={kmz} />}

      {trucks &&
        trucks.map((truck) => (
          <>
            <Marker
              key={truck.vehicleId}
              position={{
                lat: parseFloat(truck.latitude),
                lng: parseFloat(truck.longitude),
              }}
              label={{
                text: truck.plate,
                className: 'label-truck'
              }}
              onClick={() => {
                setSelectedTruck(truck);
              }}
              icon={{
                url: TruckIcon,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
            />
            {/* <Marker
              key={truck.vehicleId}
              position={{
                lat: parseFloat(truck.latitude - 0.0001),
                lng: parseFloat(truck.longitude),
              }}
              icon={{
                url: PlateIcon,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              label={{
                text: `${truck.plate}`,
                color: '#f2f',
                fontSize: '7px',
                fontWeight: 'bold',
                fontFamily: 'Roboto',
              }}
            /> */}
          </>
        ))}

      {selectedTruck && (
        <InfoWindow
          position={{
            lat: parseFloat(selectedTruck.latitude),
            lng: parseFloat(selectedTruck.longitude),
          }}
          onCloseClick={() => {
            setSelectedTruck(null);
          }}
        >
          <div>
            <h4> Placa: {selectedTruck.plate}</h4>
            <p> Ignição: {selectedTruck.ignition}</p>
            <p> Velocidade: {selectedTruck.speed} KM/H</p>
            <p> LAT: {selectedTruck.latitude}</p>
            <p> LONG: {selectedTruck.longitude}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Map);
