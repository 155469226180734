import React, { useState, useEffect } from 'react';
import { useStore } from '../../hook/useStore';
import { url } from '../../services/api.js'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import RouteMap from '../../components/RouteMap';
import Map from '../../components/Map';
import SelectRoute from '../../components/SelectRoute';
import api from '../../services/api';
import ArrowBack from '../../components/ArrowBack'

const useStyles = makeStyles((theme) => ({
  select: {
    position: 'absolute',
    zIndex: 1,
    maxWidth: "500px",
    width: '90%',
    marginTop: 10,
    left: '50%',
    transform: 'translateX(-50%)',
    paddingLeft: 20,
    paddingRight: 20,
  },
  plate:{
    float: 'right',
  },

  days: {
    backgroundColor: '#fff',
    boxShadow: theme.asagee.shadow,
    position: 'absolute',
    marginTop: 67,
    zIndex: 1,
    maxWidth: "460px",
    width: 'calc(100% - 100px)',
    left: '50%',
    paddingLeft: 20,
    paddingRight: 20,
    borderSpacing: 0,
    border: '1px solid #bbb',
    borderRadius: '16.5px',
    transform: 'translateX(-50%)',
    '& li': {
      width: '100%',
      textAlign: 'left',
      padding: '2px 5',
      listStyle: 'none',
      lineHeight: '1.5',
    },
    '& h4':{
      color: theme.palette.primary.main,
      marginBottom: '10px',
      marginTop: '3px',
      textAlign: 'center',
    },
    '& li:last-child':{
      marginBottom: '10px',

    }
  }
}));

export default function Routes() {
  const [kmzUrl, setKmzUrl] = useState('');
  const [routes, setRoutes] = useState([]);
  const [route, setRoute] = useState(0);
  const [daysOfRoute, setDaysOfRoute] = useState([]);
  const [trucks, setTrucks] = useState([]);

  const { setTitle, app } = useStore();
  const urlWithoutApi = url.replace('api/', '');
  const classes = useStyles();

  const history = useHistory();

  const weekdaysList = {
    0: 'Segunda',
    1: 'Terça', 
    2: 'Quarta', 
    3: 'Quinta', 
    4: 'Sexta', 
    5: 'Sábado', 
    6: 'Domingo', 
  }


  function routeDays({weekdays})
  {
    const days = [];
    let lastDay = 0;
    let firstDay = 0;
    //get the first and last day of the week
    if(weekdays[0] && weekdays[0]['0'] !== 1){
      for(let i = weekdays.length; i !== 0; i--){
        if(weekdays[i] && weekdays[i]['3'] && lastDay === 0){
          lastDay = i;
        }
        if(weekdays[i] && weekdays[i]['3'] && firstDay > i){
          firstDay = i;
        }
      }
      weekdays.forEach((weekday, index) => {
        if(weekday && weekday.length !== 0){
          if(firstDay ===  index || index === lastDay){
          days.push({
            gambi: weekday['0'],
            day: weekdaysList[index],
            plate: weekday['3'].plate
  
          })
        }
      }
      })
      return days;
    }

    weekdays.forEach((weekday, index) => {
      if(weekday && weekday['3'] && weekday.length !== 0){
        days.push({
          gambi: 1,
          day: weekdaysList[index],
          plate: weekday['3'].plate

        })
      }
    })
    return days;
  }


  const handleChange = (event) => {
    setRoute(event.target.value);
  };

  const getMapUrl = fileName => {
    return `${urlWithoutApi}storage/maps/${fileName}`;
  }

  function handleClick(...goto) {
    if (goto[0] != null) {
        history.push(goto[0])
    }
    else {
        history.goBack()
    }

}

  useEffect(() => {
    async function initGetApi() {
      const response = await api.get('veiculos');
      setTrucks(response.data.data.vehicles);
    }

    initGetApi();

    let interval = setInterval(async () => {

      let request = await api.get('veiculos');
      setTrucks(request.data.data.vehicles);

    }, 15000);

    return () => {
      clearInterval(interval) // clear the interval in the returning function
      // console.log('End interval')
    }

  }, []);

  useEffect(async () => {
    setTitle('Rotas');
  }, []);

  useEffect(() => {
    if (app && app.roads) {
      setRoutes(app.roads);
    }
  }, [app.roads]);

  useEffect(() => {
    if (!route) return;
    setKmzUrl(getMapUrl(route.map ? route.map : null))
    setDaysOfRoute( routeDays(route) )
  }, [route])

  //  console.log('routes', platesOfRoute[0]);
  //  if(platesOfRoute[0]){
  //   console.log('routes', platesOfRoute[0]['3']);
  //  } 
   
  console.log('routes', routes);

  return (
    <>
      <SelectRoute className={classes.select} routes={routes} route={route} handleChange={handleChange} />

     { daysOfRoute && daysOfRoute.length !== 0 ? <ul className={classes.days}>
      <h4>Dias e placas da rota</h4>
        {/* {daysOfRoute && daysOfRoute.map(item => (<li>{item.day}  <span className={classes.plate}>{item.plate}</span></li>))} */}
        {daysOfRoute && daysOfRoute[0].gambi === 1 ? daysOfRoute.map(item => (<li>{item.day}  <span className={classes.plate}>{item.plate}</span></li>))
         : <li>{daysOfRoute[0].day} até {daysOfRoute[1].day}  os Veiculos  <span>{daysOfRoute[0].plate}</span></li>}
        {/* {daysOfRoute && daysOfRoute.map(item => (<li>{item.day}  <span className={classes.plate}>{item.plate}</span></li>))} */}
          
      </ul>: null}

      <Map trucks={trucks} kmz={kmzUrl} />

    </>
  );
}