import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Button, Slide } from '@material-ui/core';




import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogModal({needOpen = false, title='Titulo', description='Descrição', onClose=null, onConfirm=null, confirmText = 'Aceitar', cancelText = 'Cancelar'}) {

    const handleClose = (event) => {
    event.stopPropagation();
        if (onClose) {
            onClose(true);
        }
    };

    const handleAccept = (event) => {
        event.preventDefault();
        if (onConfirm) {
            onClose(true);
            onConfirm(true);
        }
    }

    return (
                <Dialog
                    open={needOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Typography>
                               {description}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {cancelText}
                        </Button>
                        <Button onClick={handleAccept} color="primary">
                            {confirmText}
                        </Button>
                    </DialogActions>
                </Dialog>        )
}
