import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    android: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    }
});

export default function InstallAndroid() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const classes = useStyles();

    useEffect(function () {
        window.addEventListener('beforeinstallprompt', function (e) {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e);
        });
    }, [])

    function handleInstall() {
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null)
            });
    }
//
    return (
        <>
            {deferredPrompt &&
                <Box my={4}>
                        <Button variant="contained" color="primary" onClick={handleInstall} className={classes.android}>
                            Instalar Aplicativo
                        </Button>
                </Box>
            }
        </>
    );
}