import React, { useEffect, useState } from 'react';
import { useStore } from '../../hook/useStore';
import Address from '../../components/Address'
import AddressForm from '../../components/AddressForm'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useAddress } from '../../hook/useAddress';
import { Typography  } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    boxButton: {
        textAlign: 'center'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function AlertMe() {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [routes, setRoutes] = useState('');

    const handleLoadingClose = () => {
        setLoading(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleDeleteAddress(id) {
        handleLoading(deleteAddress(id));
    }
    
    function handleChangeAlert(id, checked) {
        handleLoading(changeAlert(id, checked));
    }

    const { setTitle, app } = useStore();
    const {
        getAddresses,
        addresses,
        deleteAddress,
        addAddress,
        changeAlert,
    } = useAddress();



    useEffect(() => {
        setTitle('Alerte me');
    }, []);

    useEffect(() => {
        handleLoading(getAddresses());
    }, []);


    function handleLoading(promise) {
        setLoading(true);
        console.log('typeof promise', typeof promise);
        promise.finally(() => setLoading(false));
        // if(typeof promise)
    }

    useEffect(() => {
        if (app && app.roads) {
          setRoutes(app.roads);
        }
      }, [app.roads]);

    const classes = useStyles();

    return (
        <section>
            <Container>

                <Box mt={2} >
                    <Typography>Você pode cadastrar seu número de telefone e endereço para receber um alerta no dia da coleta.</Typography>
                </Box>

                <Box my={1} className={classes.boxButton}>
                    <IconButton onClick={() => handleClickOpen()} color="primary" aria-label="add to shopping cart">
                        <AddCircleIcon fontSize="large" />
                    </IconButton>
                </Box>

                <Box mt={4}>
                    {addresses && addresses.length > 0 && addresses.map(address => (<Address handleDeleteAddress={handleDeleteAddress} address={address} handleChangeAlert={handleChangeAlert} />))}
                </Box>

            </Container>

            <AddressForm open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} addAddress={addAddress} handleLoading={handleLoading} routes={routes} />

            <Backdrop className={classes.backdrop} open={loading} onClick={handleLoadingClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </section>
    );
}

export default AlertMe;