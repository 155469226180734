import React, { useEffect } from 'react';
import { useStore } from '../../hook/useStore';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function Dicas() {

    const { setTitle, app } = useStore();
    const tips = app.tips;

    console.log('tips', tips);

    useEffect(() => {
        setTitle('Dicas de separação');
    }, []);
    

    const Accordion = withStyles({
        root: {
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        expanded: {},
    })(MuiAccordion);

    const AccordionSummary = withStyles(
        (theme) => ({
            root: {
                // border: 0,
                boxShadow: theme.asagee.shadow,
                borderRadius: theme.asagee.radius,
                marginBottom: 15,
                minHeight: 56,
                color: theme.palette.primary.main,

                '&$expanded': {
                    minHeight: 56,
                    backgroundColor: theme.palette.primary.main,
                    color: '#fff'
                }
            },
            content: {
                '&$expanded': {
                    margin: '12px 0',
                },
            },
            expanded: {},
        })

    )(MuiAccordionSummary);

    const AccordionDetails = withStyles((theme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }))(MuiAccordionDetails);

    return (
        <Container>
            <Box my={4}>
                {tips && tips.map(item => (
                    <Accordion >
                        <AccordionSummary boxShadow={5} aria-controls="panel1d-content" id="panel1d-header">
                            <Box fontWeight={900}>
                                {item.title}
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                            <div dangerouslySetInnerHTML={ {__html: item.introtext} } />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

            </Box>
        </Container>
    );
}