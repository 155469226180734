import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pwa: {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        padding: 15,
        borderRadius: '10px',
        position: 'fixed',
        bottom: 35,
        zIndex: 2000,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: 300
    },
    text: {
        margin: 0,
        padding: 0,
        fontSize: 14
    },
    arrow: {
        width: '30px',
        height: '30px',
        backgroundColor: theme.palette.primary.main,
        zIndex: -1,
        transform: 'rotate(45deg) translateX(-50%)',
        position: 'absolute',
        left: '49%',
        top: 'calc(100% - 5px)'
    },
    boxText: {
        zIndex: 3300,
        position: 'relative'
    },
    plus: {
        width: 30,
        height: 30,
        marginRight: 15,
        fill: '#fff'
    },
    row: {
        display: 'flex',
        alignItems: 'center'
    },
    share: {
        width: 30,
        height: 30,
        fill: '#fff',
        marginLeft: 4,
        marginRight: 4,
        marginTop: 4
    }

}));

export default function InsallIphone() {

    const classes = useStyles();

    return (
        <div className={classes.pwa}>
            <div className={classes.row}>
                <div style={{display: 'flex'}} >
                    <svg version="1.1" className={classes.plus} x="0px" y="0px" viewBox="0 0 489.8 489.8">
                        <g>
                            <g>
                                <path d="M438.2,0H51.6C23.1,0,0,23.2,0,51.6v386.6c0,28.5,23.2,51.6,51.6,51.6h386.6c28.5,0,51.6-23.2,51.6-51.6V51.6
			C489.8,23.2,466.6,0,438.2,0z M465.3,438.2c0,14.9-12.2,27.1-27.1,27.1H51.6c-14.9,0-27.1-12.2-27.1-27.1V51.6
			c0-14.9,12.2-27.1,27.1-27.1h386.6c14.9,0,27.1,12.2,27.1,27.1V438.2z" />
                                <path d="M337.4,232.7h-80.3v-80.3c0-6.8-5.5-12.3-12.3-12.3s-12.3,5.5-12.3,12.3v80.3h-80.3c-6.8,0-12.3,5.5-12.3,12.2
			c0,6.8,5.5,12.3,12.3,12.3h80.3v80.3c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-80.3h80.3c6.8,0,12.3-5.5,12.3-12.3
			C349.7,238.1,344.2,232.7,337.4,232.7z" />
                            </g>
                        </g>
                    </svg>

                </div>

                <div className={classes.boxText}>
                    <p className={classes.text}>
                        Instale esse webapp no seu Iphone: Aperte 
                        <svg className={classes.share} id="Capa_1" enable-background="new 0 0 551.13 551.13" viewBox="0 0 551.13 551.13"><path d="m465.016 172.228h-51.668v34.446h34.446v310.011h-344.457v-310.011h34.446v-34.446h-51.669c-9.52 0-17.223 7.703-17.223 17.223v344.456c0 9.52 7.703 17.223 17.223 17.223h378.902c9.52 0 17.223-7.703 17.223-17.223v-344.456c0-9.52-7.703-17.223-17.223-17.223z"/><path d="m258.342 65.931v244.08h34.446v-244.08l73.937 73.937 24.354-24.354-115.514-115.514-115.514 115.514 24.354 24.354z"/></svg>
                         e então Adicionar à Tela de Inicio
                    </p>
                </div>
            </div>
            <div className={classes.arrow}></div>

        </div>
    );
}