import React, { useState } from 'react';
import { Container, Box, Backdrop, CircularProgress, Button, Snackbar } from '@material-ui/core';
import LogoMd from '../../assets/images/logo-reciclar-v-blue.svg';
import Logo from '../../assets/images/logo-reciclar-h-blue.svg';
import TextFieldAsagee from '../../components/TextFieldAsagee';
import ButtonAsagee from '../../components/ButtonAsagee';
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import api from '../../services/api'
import axios from 'axios';
import {useStore} from '../../hook/useStore';
import {useAuth} from '../../hook/useAuth';
import ArrowBack from '../../components/ArrowBack'
import validator from 'validator';
import { useLocation } from 'react-router';
import queryString from 'query-string';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Login() {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');

    const [message, setMessage] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState('success');

    const classes = useStyles();
    const history = useHistory();
    const {storeToken, setUser} = useAuth();

    const theme = useTheme();
    const sm = theme.breakpoints.values.sm;

    const location = useLocation();

    const [token, setToken] = useState(queryString.parse(location.search).token);

    console.log(token);

    async function handleClickLink() {
        if ( ! email) return false;

        setLoading(true);

        try {
            const data = await api.post('resetPassword', {email,token,password,password_confirmation} );
           if(data.status === 200 && data.data.code === 200){
                    setMessageType('success');
                    setMessage('Senha alterada com sucesso, estamos redirecionando para a tela de login');
                    setShowMessage(true);
                   
                    setTimeout(continueExecution, 5000);
                
                    function continueExecution()
                    {
                        history.push('/login')
                    }
            }
            
        } catch(error){
            setLoading(false);
            setMessageType('error');
            setMessage('Tente novamente!');
            setShowMessage(true);
        
            console.log(error);
        
        }

        setLoading(false);
    }

    const isValidPassword = () => {
        if (validator.isEmpty(password) && validator.isEmpty(password_confirmation))
          return true;
        if (password !== password_confirmation) return false;
        if (!validator.isLength(password, { min: 6, max: 20 })) return false;
        return true;
      };

    function handleClick(...goto) {
        if(goto[0] != null){
        history.push(goto[0])
        }
        else{
         history.goBack()
        }

    }

    return (
        <Container maxWidth="sm">
            <ArrowBack  click={handleClick} goto={"/login"}/>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={showMessage} autoHideDuration={6000} onClose={() => setShowMessage(false)}>
                <Alert severity={messageType}>
                    {message}
                </Alert>
            </Snackbar>

            <Box my={4}>
                <Box my={2}>
                    <picture >
                        <source media={`(max-width: ${sm}px)`} srcSet={Logo} />
                        <img style={{ maxWidth: '300px', margin: '0 auto', display: 'block' }} src={LogoMd} alt="" />
                    </picture>
                </Box>
                <Box my={4}>
                    <form noValidate autoComplete="off">
                         <Box my={2}>
                            <TextFieldAsagee value={email} onChange={event => setEmail(event.target.value)} fullWidth id="outlined-basic" label="E-mail" variant="outlined" />
                        </Box>
                        <Box my={1}>
                        <TextFieldAsagee
                            error={!isValidPassword()}
                            list="autocompleteOff"
                            autoComplete="nope"
                            required
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            fullWidth
                            type="password"
                            id="outlined-basic"
                            label="Senha"
                            variant="outlined"
                        />
                        </Box>
                        <Box my={1}>
                        <TextFieldAsagee
                            error={!isValidPassword()}
                            list="autocompleteOff"
                            autoComplete="nope"
                            required
                            value={password_confirmation}
                            onChange={(event) => setPassword_confirmation(event.target.value)}
                            fullWidth
                            type="password"
                            id="outlined-basic"
                            label="Repita a senha"
                            variant="outlined"
                        />
                        </Box>

                        <Box my={1}>
                            <ButtonAsagee onClick={handleClickLink} fullWidth variant="contained" color="primary">
                                Alterar senha
                            </ButtonAsagee>

                        </Box>
                    </form>
                </Box>

            </Box>
        </Container>
    );
}