import React, { useState } from 'react';
import { Container, Box, Backdrop, CircularProgress, Button, Snackbar } from '@material-ui/core';
import LogoMd from '../../assets/images/logo-reciclar-v-blue.svg';
import Logo from '../../assets/images/logo-reciclar-h-blue.svg';
import TextFieldAsagee from '../../components/TextFieldAsagee';
import ButtonAsagee from '../../components/ButtonAsagee';
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import api from '../../services/api'
import axios from 'axios';
import {useStore} from '../../hook/useStore';
import {useAuth} from '../../hook/useAuth';
import ArrowBack from '../../components/ArrowBack'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Login() {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);

    const [message, setMessage] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState('success');

    const classes = useStyles();
    const history = useHistory();
    const {storeToken, setUser} = useAuth();

    const theme = useTheme();
    const sm = theme.breakpoints.values.sm;

    async function handleClickLink() {
        if ( ! email) return false;

        setLoading(true);

        try {
            const data = await api.post('forgotPassword', {email} );

            if(data.status === 200 && data.data.code === 200){
                    setMessageType('success');
                    setMessage('E-mail enviado com sucesso!');
                    setShowMessage(true);
                //history.push('/')
            }
            console.log('data');
            console.log(data);
            
        } catch(error){
            setLoading(false);
            if(error.response.data.data === "passwords.throttled"){
                setMessageType('error');
                setMessage('Você está tentando muitas vezes. Tente novamente em alguns minutos.');
                setShowMessage(true);
            }else{
                setMessageType('error');
                setMessage('Tente novamente!');
                setShowMessage(true);
            }
            
            /*let message = (error.response && error.response.data && error.response.data.data) ? error.response.data.data : 'Tente novamente!';
            setLoading(false);
            setMessageType('error');
            setMessage(message);
            setShowMessage(true);*/
        }

        setLoading(false);
    }

    function handleClick(...goto) {
        if(goto[0] != null){
        history.push(goto[0])
        }
        else{
         history.goBack()
        }

    }

    return (
        <Container maxWidth="sm">
            <ArrowBack  click={handleClick} goto={"/login"}/>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={showMessage} autoHideDuration={6000} onClose={() => setShowMessage(false)}>
                <Alert severity={messageType}>
                    {message}
                </Alert>
            </Snackbar>

            <Box my={4}>
                <Box my={2}>
                    <picture >
                        <source media={`(max-width: ${sm}px)`} srcSet={Logo} />
                        <img style={{ maxWidth: '300px', margin: '0 auto', display: 'block' }} src={LogoMd} alt="" />
                    </picture>
                </Box>
                <Box my={4}>
                    <form noValidate autoComplete="off">
                        <Box my={1}>
                            <TextFieldAsagee value={email} onChange={event => setEmail(event.target.value)} fullWidth id="outlined-basic" label="E-mail" variant="outlined" />
                        </Box>

                        <Box my={1}>
                            <ButtonAsagee onClick={handleClickLink} fullWidth variant="contained" color="primary">
                                Recuperar senha
                            </ButtonAsagee>

                        </Box>
                    </form>
                </Box>

            </Box>
        </Container>
    );
}