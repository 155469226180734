import React, { useState, useEffect } from 'react';
import Map from '../../components/Map';
import EmptyTrucks from '../../components/EmptyTrucks';
import { useStore } from '../../hook/useStore';
import api from '../../services/api';
import { useAuth } from '../../hook/useAuth';
import { useHistory } from 'react-router-dom';

export default function Truck() {
  const { setTitle, setTopShadow } = useStore();
  const [trucks, setTrucks] = useState([]);
  const [message, setMessage] = useState(null);

  const { getUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    setTitle('Onde está o Caminhão');
    setTopShadow(false);
  }, []);

  useEffect(() => {
    async function initGetApi() {
      const response = await api.get('veiculos');

      setTrucks(response.data.data.vehicles);
      if (response.data.data.message) setMessage(response.data.data.message);
    }

    initGetApi();

    let interval = setInterval(async () => {
      let request = await api.get('veiculos');
      setTrucks(request.data.data.vehicles);

      if (request.data.data.message) setMessage(request.data.data.message);

      // console.log('request.data.data.vehicles', request.data.data.vehicles);
      console.log('----------------------------------------------------------');
      request.data.data.vehicles.map((item) => {
        console.table({
          placa: item.plate,
          ligado: item.ignition,
          latitude: item.latitude,
          longitude: item.longitude,
        });
      });
      console.log('----------------------------------------------------------');

      console.log('GET');
    }, 15000);

    console.log('Start interval');

    return () => {
      clearInterval(interval); // clear the interval in the returning function
      console.log('End interval');
    };
  }, []);

  const content = () => {
    if (message) {
      return <EmptyTrucks message={message} />;
    } else {
      return <Map trucks={trucks} />;
    }
  };

  return content();
}
