import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { useHistory } from "react-router-dom";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useStore } from '../hook/useStore';
import { useAuth } from '../hook/useAuth';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {

    },
    title: {
        flexGrow: 1,
        display: 'block',
    },
}));


function TopNav({ andrey }) {
    const history = useHistory();
    const { logout, getUser } = useAuth();
    const classes = useStyles();
    let { title, topShadow } = useStore();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickItemMenu = page => {
        console.log('page', page)
        handleClose();
        // console.log(history)
        history.push(page);
    }

    const refreshPage = async () => {

        if(navigator.vibrate) navigator.vibrate(1000);
        

        await cleanCache();


        window.location.reload(true);
    }

    const cleanCache = async () => {
        const keys = await caches.keys();

        keys.map(async cacheName => {
            console.log('teste');
            await caches.delete(cacheName);
        })       
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const quit = () => {
        logout();
        history.push('/');
    }

    const handleShare = () => {
        navigator.share({
            title: 'Projeto reciclar Xangri-lá',
            url: 'https://app.reciclarxangrila.com.br/',
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
    }


    const isLoged = getUser().length > 0;


    return (
        <div className={classes.root}>
            <AppBar position="static" elevation={topShadow ? 5 : 0}>
                <Toolbar>

                    <Typography className={classes.title} variant="h6" noWrap>
                        {title}
                    </Typography>

                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>

                </Toolbar>

            </AppBar>

            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={() => handleClickItemMenu('/')}>Home</MenuItem>

                {/* novos */}
                <MenuItem onClick={() => handleClickItemMenu('dicas')}>Dicas de separação</MenuItem>
                <MenuItem onClick={() => handleClickItemMenu('coleta')}>Grade de Coletas/Bairros</MenuItem>
                <MenuItem onClick={() => handleClickItemMenu('alerta')}>Alerte-me</MenuItem>
                
                {/* novos end  */}
                <MenuItem onClick={() => handleClickItemMenu('contato')}>Contato</MenuItem>
                <MenuItem onClick={() => handleClickItemMenu('termos')}>Termos</MenuItem>
                <MenuItem onClick={() => handleClickItemMenu('Ajuda')}>Ajuda</MenuItem>
                <MenuItem onClick={() => refreshPage()}>Atualizar</MenuItem>

                {navigator.share && <MenuItem onClick={() => handleShare()}>Compartilhar</MenuItem>}



                <Divider />
                {getUser() && <MenuItem onClick={() => handleClickItemMenu('perfil')}>Perfil</MenuItem>}
                {getUser() && <MenuItem onClick={() => quit()}>Logout</MenuItem>}
                {!getUser() && <MenuItem onClick={() => handleClickItemMenu('login')}>Logar</MenuItem>}
                {!getUser() && <MenuItem onClick={() => handleClickItemMenu('registrar')}>Cadastrar</MenuItem>}



            </Menu>

        </div>
    );
}

export default TopNav;