import axios from 'axios';

console.log('process.env', process.env)

const url = process.env.NODE_ENV === 'production' ? 'https://reciclarxangrila.com.br/api/' : 'http://127.0.0.1:8000/api/';

const api = axios.create({
    'baseURL': url,
});

export {url};
export default api;
