import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Container, Typography, Box } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    svg: {
        fontSize: '100px',
        color: '#aaa',
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 900,

    }
}));

export default function EmptyTrucks({ message = 'Nenhum caminhão está em circulação no momento.' }) {
    const classes = useStyles();

    return (
        <Container className={classes.root}>
            <Box my={3}>
                <AccessTimeIcon className={classes.svg} />

                <Typography variant="h5" className={classes.title}>
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                </Typography>

            </Box>
        </Container>
    );
}