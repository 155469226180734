import { Container, Box, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStore } from '../../hook/useStore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#35e676',
        color: "#fff"
    },
}));

export default function Terms() {

    const { setTitle } = useStore();

    useEffect(() => {
        setTitle('Termos de Uso');
    }, []);

    return (
        <Container>
            <Box my={4}>
                <Typography>
                    O presente aceite e termos de uso aplica-se ao Aplicativo da Coleta Seletiva do Município de Xangri-lá – APP COLETA SELETIVA, não sendo válido para outros aplicativos ou sites disponibilizados pela Prefeitura Municipal de Xangri-lá.
                    <br />
                    O APP COLETA SELETIVA acessa, coleta, utiliza e disponibiliza informações de identificação pessoal, como telefone e endereço, número de acesso ao APP, entre outros para a Prefeitura Municipal de Xangri-lá e para a Associação de Agentes Econômicos e Ecológicos de Xangri-lá – ASAGEE. 
                    <br />
                    O Município de Xangri-lá e a ASAGEE têm o compromisso de proteção à privacidade dos dados dos usuários do aplicativo não se envolvendo na prática de venda ou intercâmbio de dados pessoas com terceiros para fins promocionais.
                    <br />
                    Além das informações pessoais que você nos fornece (telefone e localização), o APP COLETA SELETIVA utiliza tecnologia que permite ao Município de Xangri-lá e a ASAGEE coletar determinadas informações técnicas. Estas informações técnicas incluem seu endereço de protocolo de internet e o sistema operacional do seu dispositivo
                    <br />
                    Ao utilizar o APP COLETA SELETIVA, você reconhece e aceita a coleta, utilização e divulgação de informações de identificação pessoal para os fins descritos no presente aceite e termos de uso.
                    <br />
                    O APP COLETA SELETIVA também poderá fornecer dados estatísticos e compartilhar informações gerais a respeito do funcionamento e uso do APP e modelos de tráfego, além de outras informações relacionadas ao APP COLETA SELETIVA a terceiros idôneos, ressalvando-se informações pessoais dos usuários.
                    <br />
                    O APP COLETA SELETIVA não fornecerá informações pessoais a terceiros sem sua permissão, exceto quando responder às suas solicitações ou consultas, ou se, de boa-fé, o  APP COLETA SELETIVA acreditar que a divulgação das informações é necessária para responder a reclamações de que o conteúdo que você submeteu infringe os direitos de terceiros ou é necessário para proteger os direitos, propriedade e/ou segurança do Município de Xangri-lá e a da ASAGEE, dos usuários do APP e/ou do público em geral.
                </Typography>
            </Box>
        </Container>
    );
}