import React, { useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Home from './pages/Home'
import Truck from './pages/Truck'
import Tips from './pages/Tips'
import CollectionGrid from './pages/CollectionGrid'
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Teste from "./pages/Teste";
import Routes from "./pages/Routes";
import Help from "./pages/Help";
import AlertMe from "./pages/AlertMe";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import Hours from "./pages/AlertMe/Hours";
import Andrey from './components/Andrey';
import { useStore } from './hook/useStore';
import { useAuth } from './hook/useAuth';
import { AddressContextProvider } from './contexts/AddressContext'




export default function Router(props) {

    const [showIphoneInstall, setShowIphoneInstall] = useState(false);
    const [showAndroidInstall, setShowAndroidInstall] = useState(false);



    function checkDevice() {
        const isIos = () => {
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test(userAgent);
        }
        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
        // Verifica se deve exibir notificação popup de instalação:
    
        if (!isInStandaloneMode()) {
          if (isIos()) {
            setShowIphoneInstall(true)
          } else {
            setShowAndroidInstall(true);
          }
        }
      }


    return (
        <Switch>

            <Route exact path="/">
                <Andrey>
                    <Home showAndroidInstall={showAndroidInstall}/>
                </Andrey>
            </Route>

            <Route exact path="/caminhoes">
                <Andrey>
                    <Truck />
                </Andrey>
            </Route>

            <Route exact path="/dicas">
                <Andrey>
                    <Tips />
                </Andrey>
            </Route>

            <Route exact path="/coleta">
                <Andrey>
                    <CollectionGrid />
                </Andrey>
            </Route>

            <Route exact path="/contato">
                <Andrey>
                    <Contact />
                </Andrey>
            </Route>

            <Route exact path="/termos">
                <Andrey>
                    <Terms />
                </Andrey>
            </Route>

            <Route exact path="/esqueci-senha">
                    <ForgotPassword />
            </Route>

            
            <Route exact path="/resetar-senha">
                    <ResetPassword /> 
            </Route>

            <Route exact path="/rotas">
                <Andrey>
                    <Routes />
                </Andrey>
            </Route>

            <PrivateRoute exact path="/alerta">
                <Andrey>
                    <AddressContextProvider>
                        <AlertMe />
                    </AddressContextProvider>
                </Andrey>
            </PrivateRoute>

            <PrivateRoute exact path="/perfil">
                <Andrey>
                    <AddressContextProvider>
                        <Profile />
                    </AddressContextProvider>
                </Andrey>
            </PrivateRoute>

            <Route exact path="/login">
                <Login />
            </Route>

            <Route exact path="/registrar">
                <Register />
            </Route>

            <Route exact path="/teste">
                <Teste />
            </Route>

            <Route exact path="/ajuda">
                <Andrey>
                <Help />
                </Andrey>
            </Route>

        </Switch>
    );
}

function PrivateRoute({ children, ...rest }) {

    let auth = useAuth();

    // let isLoged = 1;
    let isLoged = auth.getUser();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoged ? children : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );

}