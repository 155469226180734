import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';

export const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {

    const [userData, setUserData] = useState();
    const [addresses, setAddresses] = useState([]);

    const history = useHistory();

    function storeToken(token) {
        if (localStorage.setItem('token', token)) return true;
    }

    function deleteToken() {
        if (localStorage.removeItem('token')) return true;
    }

    function getToken() {
        return localStorage.getItem('token');
    }

    function setUser(user) {
        setUserData(user)
        localStorage.setItem('user', JSON.stringify(user))
    }

    function deleteUser() {
        localStorage.removeItem('user');
        setUserData(null)
        return true;
    }

    function logout() {
        return deleteUser();
    }

    function checkResponseAuth(response) {

        if (response &&  response.status != 401) {
            return true;
        }

        return logout();
    }

    function getUser() {
        if (userData) return userData;
        if (localStorage.getItem('user')) return JSON.parse(localStorage.getItem('user'));
        return false;
    }

    function verifyToken() {
        const token = getToken();
        console.log('token', token)
        if (!token) {
            setUserData(null);
            return false;
        }

        return token;
    }
   
    //  GET USER BY TOKEN ON STORAGE
    async function login() {
        alert('kk usa sim')
        const token = getToken();
        console.log('token', token)
        if (!token) {
            setUserData(null);
            return false;
        }

        try {
            const response = await api.get('user', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

            if (response.status !== 200) return false;
            if (!response.data.data) return false;

            setUserData(response.data.data);
            console.log('response.data.data', response.data.data)

            return response.data.data;

        } catch (error) {
            console.log('error', error)
        }

        return false;
    }

    return (
        <AuthContext.Provider
            value={{
                userData,
                login,
                setUserData,
                setUser,
                getUser,
                logout,

                storeToken,
                deleteToken,
                getToken,
                verifyToken,
                checkResponseAuth
               
            }} >
            {children}
        </AuthContext.Provider>
    )
}