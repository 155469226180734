import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        borderSpacing: 0,
        border: '1px solid #bbb',
        borderRadius: '16.5px',
        width: '100%',
        // margin: '20px',
        '& th:first-child': {
            borderRadius: '15px 0 0 0',

        },
        '& tr td:first-child, & tr th:first-child': {
            textAlign: 'left',
            paddingLeft: '10px'
        },
        '& th': {
            position: 'sticky',
            top: 0,


            border: '1px solid #bbb',
            background: '#aaaaaa',
            color: '#ffffff',
            padding: '10px 4px',
        },
        '& td': {
            border: '1px solid #bbb',
            padding: '10px 4px',
            textAlign: 'center',
            color: '#2a65b4',
            fontSize: '.9em',

        },
        '& tr td:first-child': {
            color: '#888888'
        },
        '& tr:last-child td:first-child': {
            borderRadius: '0 0 0 15px'
        },
        '& th:last-child': {
            borderRadius: '0 15px 0 0'
        },
        '& tr:last-child td:last-child': {
            borderRadius: '0 0 15px 0'
        }

    }
}));

export default function CollectionTable({ list }) {
    const classes = useStyles();
    // Math.random() < 0.5,

    /*

    protected $weekdays = [
        0 => 'Segunda',
        1 => 'Terça',
        2 => 'Quarta',
        3 => 'Quinta',
        4 => 'Sexta',
        5 => 'Sábado',
        6 => 'Domingo'
    ];
    */

    const weekdays = ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'];
    console.log('list AKI: ', list)

    return (
        <table className={classes.root}>
            <thead>
                <tr>
                    <th>Local</th>
                    {weekdays && weekdays.map((item) => {
                        return (<th>{item}</th>);
                        })}
                </tr>
            </thead>
            <tbody>

                {list && list.map(item => (
                    <tr key={item.id}>
                        <td>{item.title}</td>
                        {item.weekdays && item.weekdays.map(weekday=>{
                            let content = '';

                            for (const key in weekday) {
                                if (Object.hasOwnProperty.call(weekday, key)) {
                                    const element = weekday[key];

                                    if(element !== 0 && element !== 1){
                                        // content += ' ' +element.tag;
                                        content += 'X';
                                    }
                                }
                            }
                            return (<td>{content}</td>);
                        })}
                       
                    </tr>
                ))}

            </tbody>
        </table>
    );
}