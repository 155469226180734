import { Button, Container, Box, Typography, Link } from '@material-ui/core';
import React, { useEffect } from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { useStore } from '../../hook/useStore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#35e676',
        color: "#fff"
    },
}));

export default function Contact() {

    const { setTitle, app } = useStore();

    const configs = app.configs;

    const whatsapp = configs && configs.filter(item=>item.tag === 'whatsapp')[0];
    const contactText = configs && configs.filter(item=>item.tag === 'contact_text')[0];
    const whatsappMessage = configs && configs.filter(item=>item.tag === 'whatsapp_message')[0];
        

    useEffect(() => {
        setTitle('Contato');
    }, []);

    const classes = useStyles();

    return (
        <Container>
            <Box my={4}>

                <Typography>
                    {contactText && contactText.content && contactText.content}
                </Typography>
                <Box my={4} style={{ textAlign: 'center' }}>
                {whatsapp && whatsapp.content &&
                    <Link underline="none" href={`https://api.whatsapp.com/send?phone=55${whatsapp.content}${whatsappMessage && whatsappMessage.content? '&text='+whatsappMessage.content : ''}`} target="_blank">
                        <Button

                            variant="contained"
                            className={classes.button}
                            startIcon={<WhatsAppIcon />}

                        >
                            Whatsapp
                        </Button>
                    </Link>
                }

                </Box>
            </Box>
        </Container>
    );
}