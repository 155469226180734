import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from "react-router-dom";


import { useStore } from '../hook/useStore';
import { useAuth } from '../hook/useAuth';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
}));

const drawerWidth = 240;

function Menu() {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { getUser } = useAuth();

    let { menu, setMenu } = useStore();

    const handleDrawerClose = () => {
        setMenu(false);
    };

    const handleClickItemMenu = page => {
        setMenu(false);
        console.log(history)
        history.push(page);
    }

    return (
        <Drawer
            className={classes.drawer}
            ModalProps={{ onBackdropClick: handleDrawerClose }}
            anchor="left"
            open={menu}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>

            <Divider />

            <List>
                <ListItem button key='dicas' onClick={() => handleClickItemMenu('dicas')}>
                    <ListItemText primary="Dicas de separação" />
                </ListItem>
            </List>
            <Divider />

            {/* <List>
                <ListItem button key='caminhoes' onClick={() => handleClickItemMenu('caminhoes')}>
                    <ListItemText primary="Meu Perfil" />
                </ListItem>
            </List>
            <Divider /> */}

            {/* <List>
                <ListItem button key='caminhoes' onClick={() => handleClickItemMenu('caminhoes')}>
                    <ListItemText primary="Tempo aproximado da coleta seletiva" />
                </ListItem>
            </List>
            <Divider /> */}

<List>
                <ListItem button key='coleta' onClick={() => handleClickItemMenu('coleta')}>
                    <ListItemText primary="Grade de Coletas/Bairros" />
                </ListItem>
            </List>
            <Divider />

            <List>
                <ListItem button key='caminhoes' onClick={() => handleClickItemMenu('rotas')}>
                    <ListItemText primary="Rotas" />
                </ListItem>
            </List>
            <Divider />

          
            <List>
                <ListItem button key='caminhoes' onClick={() => handleClickItemMenu('caminhoes')}>
                    <ListItemText primary="Tempo aproximado" />
                </ListItem>
            </List>
            <Divider />
      

         
                <List>
                    <ListItem button key='caminhoes' onClick={() => handleClickItemMenu('alerta')}>
                        <ListItemText primary="Alerte-me" />
                    </ListItem>
                </List>
                <Divider />
         
            <List>
                <ListItem button key='caminhoes' onClick={() => handleClickItemMenu('caminhoes')}>
                    <ListItemText primary="Onde está o Caminhão" />
                </ListItem>
            </List>
            <Divider />
       

            <List>
                <ListItem button key='ajuda' onClick={() => handleClickItemMenu('ajuda')}>
                    <ListItemText primary="Ajuda" />
                </ListItem>
            </List>
            <Divider />

            <List>
                <ListItem button key='contato' onClick={() => handleClickItemMenu('contato')}>
                    <ListItemText primary="Contato" />
                </ListItem>
            </List>
            <Divider />

        
        </Drawer>
    );
}

export default Menu;