import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(
    (theme) => ({
        root: {
         padding: '18.5px 14px',
         borderRadius: theme.asagee.radius,
         boxShadow: theme.asagee.shadow,
         marginBottom: 15,
        
        },
        
    
    })

)(Button);