import React, { useEffect } from 'react';
import { useStore } from '../../hook/useStore';
import Container from '@material-ui/core/Container';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 900
    }
}));

function Hours() {

    const [value, setValue] = React.useState('female');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const { setTitle, app } = useStore();
    const classes = useStyles();

    useEffect(() => {
        setTitle('Definir');
    }, []);

    return (
        <Container>
            <Box mt={4}>
                <Typography variant="h6" className={classes.title}>Defina o horário do alerta:</Typography>

                <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                        <FormControlLabel value="female" control={<Radio color="primary" />} label="30 Minutos antes" />
                        <FormControlLabel value="male" control={<Radio color="primary" />} label="1 Hora antes" />
                        <FormControlLabel value="other" control={<Radio color="primary" />} label="2 Horas" />
                    </RadioGroup>
                </FormControl>

            </Box>
        </Container>
    );
}

export default Hours;