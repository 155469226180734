import { createContext, useEffect, useState } from 'react';
import api from '../services/api';
import { useAuth } from '../hook/useAuth';

export const AddressContext = createContext({});

export const AddressContextProvider = ({ children }) => {
    const [addresses, setAddresses] = useState([]);

    const { checkResponseAuth, verifyToken } = useAuth();

    function handleResponse(response, status = 200) {
        if (response.status !== 200) return false;
        if (!response.data.data) return false;
        checkResponseAuth(response);
        setAddresses(response.data.data);
    }

    function handleError(error) {
        console.log('error', error)
        checkResponseAuth(error.response);
    }

    async function addAddress({ street, number, time, route }) {
        const token = verifyToken();

        return api.post(`address`, {
            street, number, time, route
        },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(handleResponse)
            .catch(handleError)
    }

    async function deleteAddress(id) {
        const token = verifyToken();

        return api.delete(`address/${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }, [])
            .then(handleResponse)
            .catch(handleError)

    }

    async function getAddresses() {
        const token = verifyToken();

        return api.get('address', {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(handleResponse)
            .catch(handleError)
    }

    async function changeAlert(id, checked){
        const token = verifyToken();

        return api.post(`address/switch/alert`, {
            id, checked
        },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then(handleResponse)
            .catch(handleError)
    }

    return (
        <AddressContext.Provider
            value={{
                addresses,
                getAddresses,
                deleteAddress,
                addAddress,
                changeAlert
            }} >
            {children}
        </AddressContext.Provider>
    )
}