import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(
    (theme) => ({
        root: {
            // border: 0,
            boxShadow: theme.asagee.shadow,
            borderRadius: theme.asagee.radius,
            marginBottom: 15,
            color: theme.palette.primary.main,
            outline: 'none',
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: theme.asagee.radius,
                },
              },
        
        },
        
    
    })

)(TextField);